@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat-bold";
  src: url("assets/fonts/Montserrat-BoldItalic.ttf");
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat-ligth-bold";
  src: url("assets/fonts/Montserrat-SemiBold.ttf");
}

.clip {
  clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%);
}

:root {
  --primary: #ce0b0b;
  --secondary: #181e5c;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.clipPath {
  clip-path: polygon(25% 0%, 100% 0%, 1000% 1000%, 0% 100%);
}

/* no es necesario seguir haciendo esto */
/* para eso tenemos tailwind */
.CardText {
  font-family: Montserrat;
  letter-spacing: 0px;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  color: #4a5767;
}
.BigText {
  color: #6d8095;
  font-weight: 600;
  font-size: 1.5rem;
}
.cardBold {
  font-size: 16px;
  font-weight: 600;
  color: #4a5767;
}
.cardFights {
  color: #ea0404;
}
/* tailwind tiene una clase para esto */
.flex {
  display: flex;
}

/* y para esto */
.flex-1 {
  flex: 1;
}
/* y para esto */
.justify-between {
  justify-content: space-between;
}

.slash {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid blue;
  transform: rotate(45deg);
}

.BueFont {
  font-family: Montserrat;
  color: #2f3576;
}
.smallText {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #404f60;
}
.smallerText {
  @apply text-xs text-[#404f60] font-bold;
}
.BadgeText {
  text-align: left;
  font: normal normal 500 14px/24px Montserrat;
  letter-spacing: -0.43px;
  color: #3d4f60;
  opacity: 1;
}
.BadgeTextSideMenu {
  font: normal normal 500 16px/24px Montserrat;
  letter-spacing: -0.43px;
  color: #ffffff;
  opacity: 1;
}
.TitleText {
  text-align: left;
  font: italic normal bold 14px/20px Montserrat-bold;
  letter-spacing: 0px;
  color: #162b46;
  text-transform: uppercase;
  opacity: 1;
}
.TitleTextModal {
  font: italic normal 800 29px/49px Montserrat-bold;
  letter-spacing: 0px;
  color: #2f3576;
  opacity: 1;
}
.selectText {
  text-align: left;
  font: italic normal bold 16px/25px Montserrat;
  letter-spacing: 0px;
  color: #636363;
  opacity: 1;
}
.NormalText {
  text-align: left;
  font: normal normal normal 16px/38px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.cardRusterName {
  text-align: center;
  font: normal normal 600 13px/18px Montserrat;
  letter-spacing: 0px;
  color: #162b46;
  opacity: 1;
  overflow-wrap: anywhere;
}
.cardTitle {
  text-align: left;
  font: italic normal bold 16px/23px Montserrat-bold;
  letter-spacing: 0px;
  opacity: 1;
}
.whiteTitle {
  text-align: left;
  color: white;
  font: italic normal bold 18px/23px Montserrat-bold;
  letter-spacing: 0px;
  opacity: 1;
}
.whiteTitleLarge {
  text-align: left;
  color: white;
  font: italic normal bold 50px/23px Montserrat-bold;
  letter-spacing: 0px;
  opacity: 1;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  @apply w-2;
}

*::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 14px;
  border: 1px solid white;
}

.titleBackground {
  background: #1a3150;
  background: linear-gradient(113deg, rgba(26, 49, 80, 1) 28%, rgba(255, 255, 255, 1) 29%);
}
.modalNumber {
  font: normal normal bold 16px/45px Montserrat-bold;
  letter-spacing: -0.56px;
  color: #0b0e2c;
}

.modalName {
  text-align: left;
  font: normal normal medium 18px/45px Montserrat;
  letter-spacing: -0.56px;
  color: #0b0e2c;
}
.modalDate {
  text-align: left;
  font: normal normal medium 15px/45px Montserrat-ligth-bold;
  letter-spacing: -0.56px;
  color: #0b0e2c;
}
.modalType {
  text-align: left;
  font: normal normal bold 16px/43px Montserrat-bold;
  letter-spacing: -0.5px;
  color: #404f60;
}
.mdoalNormalText {
  text-align: left;
  font: normal normal medium 16px/43px Montserrat;
  letter-spacing: -0.5px;
  color: #404f60;
}
.selectedCard {
  background: transparent linear-gradient(306deg, #0b0e2c 0%, #373c6c 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #0b0e2c0a;
  border-radius: 10px;
  opacity: 1;
}

.flexJustifyCenter {
  @apply flex justify-between flex-col flex-wrap;
}

.pulse-border{
  -webkit-animation-name: pulseBorder;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;

  animation-name: pulseBorder;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@-moz-keyframes pulseBorder  {
  0% { background-color:  rgba(178,178,178, 1); }
  50% { background-color:  rgba(178,178,178, .5); }
  100% { background-color:  rgba(178,178,178, 0); }
}

@-webkit-keyframes pulseBorder  {
  0% { background-color:  rgba(178,178,178, 1); }
  50% { background-color:  rgba(178,178,178, .5); }
  100% { background-color:  rgba(178,178,178, 0); }
}

@keyframes pulseBorder  {
  0% { background-color:  rgba(178,178,178, 1); }
  50% { background-color:  rgba(178,178,178, .5); }
  100% { background-color:  rgba(178,178,178, 0); }
}